import LoadImage from '../LoadImage'
import React, { useState, useEffect } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import CloseIcon from '../../../static/icons/close-icon.svg'
import Evgeny from '../../../static/images/welcome-popup/Evgeny.webp'
import CheckedIcon from '../../../static/icons/welcome-popup/checked.svg'
import RoundedArrow from '../../../static/icons/welcome-popup/rounded-arrow.svg'

import CodeIcon from '../../../static/icons/welcome-popup/code.svg'
import CasesIcon from '../../../static/icons/welcome-popup/cases.svg'
import DevelopersIcon from '../../../static/icons/welcome-popup/developers.svg'
import EstimateIcon from '../../../static/icons/welcome-popup/estimate.svg'

const samples = [
  { icon: CodeIcon, name: 'Code sample' },
  { icon: CasesIcon, name: 'Portfolio cases', nameFull: 'Specific portfolio cases' },
  { icon: DevelopersIcon, name: 'Developers CV' },
  { icon: EstimateIcon, name: 'Consultation', nameFull: 'Estimate & Consultation' },
]

export default ({ formData }) => {
  const breakpoint = useBreakpoint()
  const [error, setError] = useState(false)
  const [checked, setChecked] = useState(
    formData.value.samples ? (() => {
      const checked = { }
      formData.value.samples.forEach(name =>
        samples.forEach((sample, index) => {
          const isChecked =
            sample.nameFull === name ||
            sample.name === name

          if (isChecked) checked[index] = true
        })
      )
      return checked
    })() : { }
  )

  useEffect(() => {
    const onTick = () => {
      const letter = document.querySelector('.welcome-popup__message__text [style]')
      letter ? letter.removeAttribute('style') : clearInterval(intervalId)
    }

    const intervalId = setInterval(onTick, 50)
    return () => intervalId && clearInterval(intervalId)
  }, [])

  const toggleChecked = index => {
    checked[index] ?
      delete checked[index] :
      checked[index] = true

    setError(false)
    setChecked({ ...checked })
  }

  const onClick = event => {
    const keys = Object.keys(checked)
    if (keys.length) {
      formData.value.samples = keys.map(index =>
        samples[index].nameFull || samples[index].name)
    } else {
      event.preventDefault()
      setError(true)
    }
  }

  return <>
    <CloseIcon className='welcome-popup__close'/>

    <h2 className='welcome-popup__title'>
      Welcome to Zimalab!
    </h2>

    <div className='welcome-popup__message'>
      <LoadImage lazyLoad alt='Evgeny' primaryLg={ Evgeny }
        className='welcome-popup__message__avatar'/>
      <RoundedArrow className='welcome-popup__message__arrow'/>
      <div className='welcome-popup__message__cloud'>
        <h3 className='welcome-popup__message__title'>
          Hi!
        </h3>
        <p className='welcome-popup__message__text'>
          { 'Select what you are interested in and we will get back to you in 24 hours!'
            .split('').map((letter, index) =>
              <span key={ index } style={{ color: 'white' }}>{ letter }</span>
            )
          }
        </p>
      </div>
    </div>

    <div className='welcome-popup__samples'>
      { samples.map((sample, index) =>
        <div key={ index } className={ 'sample' +
          (error ? ' sample-error' : '')
        }>
          <input
            type='checkbox'
            name={ sample.name }
            id={ 'sample_' + index }
            className='sample__input'

            checked={ !!checked[index] }
            onChange={ () => toggleChecked(index) }
          />
          <label htmlFor={ 'sample_' + index }>
            <div className='sample__content'>
              <sample.icon className='sample__icon'/>
              <CheckedIcon className='sample__checked'/>
            </div>
            <p className='sample__name'>
              { breakpoint.md ? sample.name : (
                sample.nameFull || sample.name
              ) }
            </p>
          </label>
        </div>
      ) }
    </div>

    <div className='welcome-popup__footer'>
      <button className='welcome-popup__button' onClick={ onClick }>
        NEXT
      </button>
    </div>
  </>
}
