import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

import { loadScript } from '../helpers'

import favicon from '../../static/icons/favicon.png'

import WelcomePopup from '../components/WelcomePopup'

export default ({ children }) => {
  const onWebpLoad = () => {
    Modernizr.on('webp', function(result) {
      if (result) {
        let elements = document.querySelectorAll("[data-bgset]")

        elements.forEach(element => {
          if (element.hasAttribute('data-bgset-webp')) {
            const webp = element.dataset.bgsetWebp

            element.setAttribute('data-bgset', webp)
            element.removeAttribute('data-bgset-webp')
          }
        })
      }
    })
  }

  const onGoogleAnalyticsLoad = () => {
    window.dataLayer = window.dataLayer || []
    function gtag(){dataLayer.push(arguments)}
    gtag('js', new Date())

    gtag('config', 'UA-46574576-2')
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <link rel="icon" href={ favicon } />
        <style>
          {
            `
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-display: swap;
              src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-display: swap;
              src: local('Montserrat Medium'), local('Montserrat-Medium'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-display: swap;
              src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-display: swap;
              src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 700;
              font-display: swap;
              src: local('Montserrat Bold'), local('Montserrat-Bold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 900;
              font-display: swap;
              src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3g3D_u50.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 900;
              font-display: swap;
              src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3gnD_g.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }
            `
          }
        </style>
        <link rel="preload" href="https://assets.calendly.com/assets/external/widget.css" as="style" onload="this.onload=null;this.rel='stylesheet'" />

        <script>{loadScript(withPrefix('scripts/modernizr-custom.min.js'), onWebpLoad)}</script>
        <script>{loadScript('https://www.googletagmanager.com/gtag/js?id=UA-46574576-2', onGoogleAnalyticsLoad)}</script>
        <script src={ withPrefix('scripts/lazysizes.min.js') } type="text/javascript" defer />
        <script src={ withPrefix('scripts/ls.bgset.min.js') } type="text/javascript" defer />
        <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" defer />

        <script>
          { `
 (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
 (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

 ym(90935441, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
 });
          ` }
        </script>
      </Helmet>

      { children }
      <WelcomePopup/>
    </>
  )
}

