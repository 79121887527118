import React, { useEffect } from 'react'
import LoadImage from '../LoadImage'

import Evgeny from '../../../static/images/welcome-popup/Evgeny.webp'

export default ({ onClick }) => {
  useEffect(() => {
    const small = document.querySelector('.welcome-popup-small')
    let [openTimerId, closeTimerId] = [null, null]

    const onOpen = () => {
      small.classList.add('active')
      closeTimerId = setTimeout(() => {
        small.classList.remove('active')
        closeTimerId = null
      }, 2000)
    }

    openTimerId = setInterval(onOpen, 60000)
    return () => {
      openTimerId && clearInterval(openTimerId)
      closeTimerId && clearTimeout(closeTimerId)
    }
  }, [])

  return (
    <div className='welcome-popup-small' {...{ onClick }}>
      <div className='welcome-popup-small__hover'/>
      <div className='welcome-popup-small__back'/>
      <LoadImage
        className='welcome-popup-small__avatar'
        lazyLoad alt='Evgeny' primaryLg={ Evgeny }
      />
    </div>
  )
}
