import Cookies from 'js-cookie'

export default {

  isTimeout: () => {
    return Cookies.get('welcome_popup_timeout') === 'true'
  },

  setIsTimeout: () => {
    Cookies.set('welcome_popup_timeout', 'true', { expires: 30 })
  },

}

