import axios from 'axios'

const isProd = true
const emailApiUrl = isProd
  ? 'https://v4back.zimalab.com/api/createEmail'
  : 'http://demo-backend.zimalab.com/api/createEmail'

export default (formData, ReCAPTCHA) => {
  const data = {
    name: formData.fields.name.value,
    email: formData.fields.email.value,
    project: formData.fields.about.value,
    samples: formData.samples.join(', '),
    'g-recaptcha-response': ReCAPTCHA,
  }

  return axios({
    method: 'post',
    url: emailApiUrl,
    data: { ...data }
  })
}
