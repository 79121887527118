import ReCAPTCHA from 'react-google-recaptcha'
import { useSelector, useDispatch } from 'react-redux'
import React, { useState, useEffect, useRef } from 'react'
import { welcomePopupAction } from '../../redux/actions/welcomePopupAction'

import '../../styles/components/welcome-popup/_welcome-popup.scss'
import '../../styles/components/welcome-popup/_welcome-popup-small.scss'

import { Done, Fail } from './_ResultStages'
import Samples from './_Samples'
import Fields from './_Fields'

import Small from './_Small'
import Cookies from './_cookies'
import sendMail from './_sendMail'

// ReCAPTCHA key
const siteKey = '6Ler8ckZAAAAAC-USbXdlGathJgV9Ny70mBwk6sq'

export default () => {
  const welcomePopupIsOpen = useSelector(
    state => state.welcomePopupIsOpen)
  const dispatch = useDispatch()
  const recaptchaRef = useRef()

  const [stage, setStage] = useState((() =>
    Cookies.isTimeout() ? 'closed' : 'hidden'
  )())
  const formData = (() => {
    const state = useState({})
    return { value: state[0], set: state[1] }
  })()

  useEffect(() => {
    if (stage !== 'hidden') return
    const timeoutId = setTimeout(
      () => setStage('samples'), 1000 * 10)
    return () => clearTimeout(timeoutId)
  }, [])
  useEffect(() => {
    const isClosed = ['hidden', 'closed'].includes(stage)

    // disable scroll on fullpage
    if (typeof fullpage_api === 'object')
      fullpage_api.setAllowScrolling(isClosed)

    isClosed ? // disable scroll
      document.querySelector('body').style.overflow = null :
      document.querySelector('body').style.overflow = 'hidden'
  }, [stage])
  useEffect(() => {
    const isClosed = ['hidden', 'closed'].includes(stage)
    if (welcomePopupIsOpen === isClosed)
      dispatch(welcomePopupAction(!isClosed))
  }, [stage])
  useEffect(() => {
    if (['hidden', 'closed'].includes(stage))
      if (welcomePopupIsOpen) setStage('samples')
  }, [welcomePopupIsOpen])

  const onSubmit = event => {
    event.preventDefault()

    if (stage === 'samples')
      return setStage('fields')

    recaptchaRef.current.executeAsync().then(token =>
      sendMail(formData.value, token).then(
        () => setStage('done'),
        () => setStage('fail'),
      )
    )

    Cookies.setIsTimeout()
    setStage('loading')
  }
  const onClose = event => {
    // ignore bubbled events
    if (event.target !== event.currentTarget) return

    Cookies.setIsTimeout()
    setStage('closed')
    formData.set({ })
  }
  const onBack = event => {
    event.preventDefault()
    setStage('samples')
  }

  return <>
    { stage !== 'hidden' && stage !== 'closed' &&
      <div className='welcome-popup' onClick={ onClose }>
        <form className='welcome-popup__form' {...{ onSubmit }}>

          { stage === 'samples' && <Samples {...{ formData }}/> }
          { stage === 'fields' && <Fields {...{ formData, onBack }}/> }
          { stage === 'loading' && <Fields {...{ formData, onBack }} isLoading/> }

          { stage === 'done' && <Done/> }
          { stage === 'fail' && <Fail/> }

        </form>
        <ReCAPTCHA
          size='invisible'
          sitekey={ siteKey }
          ref={ recaptchaRef }
          style={{ display: 'none' }}
        />
      </div>
    }

    { stage === 'closed' &&
      <Small onClick={ () => setStage('samples') }/>
    }
  </>
}
