import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import loadable from '@loadable/component'

import '../../styles/components/contact-us.scss'

import CompanyInfo from '../CompanyInfo'
import ReviewLinks from '../ReviewLinks'
import Copyright from '../Copyright'
import Link from '../Link'

const ContactForm = loadable(() => import('./ContactForm'))
const ContactUpButtons = loadable(() => import('../ContactUpButtons'))
const SuccessMessage = loadable(() => import('./SuccessMessage'))
const ErrorMessage = loadable(() => import('./ErrorMessage'))

import Arrow from '../../../static/icons/arrow.svg'

const ContactUs = ({
  isNewDesign,
  hasCopyright,
  hasNavigation,
  prevProject,
  nextProject,
  children
}) => {
  const [submitted, setSubmitted] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [dataForm, setDataForm] = useState(null)
  const breakpoints = useBreakpoint()

  const onSubmit = val => {
    setSubmitted(val)
  }

  const onError = val => {
    setHasError(val)
  }

  const saveDataForm = data => {
    setDataForm(data)
  }

  const message = hasError ?
    <ErrorMessage
      onSubmit={ onSubmit }
      onError={ onError }
    /> :
    <SuccessMessage />

  const newDesignClass = (() => {
    if (!isNewDesign) return ''

    let newDesignClass = 'contact-us--new'

    if (breakpoints.md) {
      newDesignClass += ` ${newDesignClass}-mobile`
    }

    return newDesignClass
  })()

  return (
    <section className={ `section contact-us ${ newDesignClass } lazyload` }>
      <h2 className="caption-primary">Contact Us</h2>

      <div className="contact-us-container">
        {
          submitted ?
            message :
            <ContactForm
              data={ dataForm }
              saveDataForm={ saveDataForm }
              onSubmit={ onSubmit }
              onError={ onError }
            />
        }

        <div>
          <CompanyInfo />
          <ReviewLinks />
          <ContactUpButtons />
        </div>

      </div>
      { hasCopyright && <Copyright /> }

      {/* //this for redesign page */}
      {hasNavigation && (
        <>
          <div className="contact-us__navigation">
            <div className="contact-us__navigation-button">
              <Link
                to={ prevProject }
                svgIcon={ <Arrow className="icon--arrow" /> }
                className="link-to-project link-to-project--prev"
              >
                {
                  breakpoints.md && !isNewDesign ? 'prev' : 'previous project'
                }
              </Link>

              <Link
                to={ nextProject }
                svgIcon={ <Arrow className="icon--arrow" /> }
                className="link-to-project link-to-project--next"
              >
                {
                  breakpoints.md && !isNewDesign ? 'next' : 'next project'
                }
              </Link>
            </div>
            {
              breakpoints.md &&  (
                <div className="footer-container">
                  <div className="footer-description">
                    projects
                  </div>
                </div>
              )
            }
          </div>
        </>
        )}

      { children }
    </section>
  )
}

ContactUs.defaultProps = {
  hasCopyright: false
}

ContactUs.propTypes = {
  isNewDesign: bool,
  hasCopyright: bool,
  hasNavigation: bool,
  prevProject: string,
  nextProject: string
}

export default ContactUs
