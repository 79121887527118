import React from 'react'

import CloseIcon from '../../../static/icons/close-icon.svg'
import ResultDoneIcon from '../../../static/icons/welcome-popup/result_done.svg'
import ResultFailIcon from '../../../static/icons/welcome-popup/result_fail.svg'

export const Done = () => (
  <div className='welcome-popup__result'>
    <CloseIcon className='welcome-popup__close'/>
    <ResultDoneIcon className='welcome-popup__icon'/>
    <p className='welcome-popup__text welcome-popup__text-result'>
      Thank you for your message!
    </p>
  </div>
)

export const Fail = () => (
  <div className='welcome-popup__result'>
    <CloseIcon className='welcome-popup__close'/>
    <ResultFailIcon className='welcome-popup__icon'/>
    <p className='welcome-popup__text'>
      Something went wrong<br/>
      Please try again later
    </p>
  </div>
)
