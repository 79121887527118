import React, { useState, useEffect } from 'react'

import Home from '../../../static/icons/home.svg'
import CloseIcon from '../../../static/icons/close-icon.svg'
import Arrow from '../../../static/icons/welcome-popup/arrow.svg'

const Field = ({
  onChange, onBlur, readOnly,
  field, name, placeholder,
  autoFocus, isTextarea,
}) => {
  const Tag = isTextarea ? 'textarea' : 'input'
  let className = 'welcome-popup__field'
  if (field.error) className += ' ' + className + '-error'

  const onKeyPress = event => {
    // disable enter key
    if (event.which === 13)
      isTextarea ?
        event.stopPropagation() :
        event.preventDefault()
  }

  return (
    <div {...{ className }}>
      <Tag
        value={ field.value }
        data-error={ field.error }
        {...{ name, placeholder }}
        {...{ autoFocus, readOnly }}
        {...{ onChange, onBlur, onKeyPress }}
      />
      { field.error &&
        <div className='welcome-popup__error'>
          <p className='welcome-popup__error-text'>
            { field.error }
          </p>
          <Arrow className='welcome-popup__error-arrow'/>
        </div>
      }
    </div>
  )
}

export default ({ formData, onBack, isLoading }) => {
  const [fields, setFields] = useState((() => {
    const fields = {
      name: { value: '', error: '' },
      email: { value: '', error: '' },
      about: { value: '', error: '' },
    }

    if (formData.value.fields) {
      fields.name.value = formData.value.fields.name.value
      fields.email.value = formData.value.fields.email.value
      fields.about.value = formData.value.fields.about.value
    }

    return fields
  })())

  useEffect(() => {
    formData.value.fields = fields
  }, [fields])

  const validateName = () => {
    fields.name.error = null

    if (fields.name.value.length < 2) fields
      .name.error = 'Min. 2 characters required'

    if (fields.name.value.length > 20) fields
      .name.error = 'Max. 20 characters required'

    return !fields.name.error
  }
  const validateEmail = () => {
    fields.email.error = null

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValid = fields.email.value.toLowerCase().match(regex)
    if (!isValid) fields.email.error = 'Please enter valid email address'

    return !fields.email.error
  }

  const onBlur = event => {
    switch (event.target.name) {
      case 'name': validateName(); break
      case 'email': validateEmail(); break
    }
    setFields({ ...fields })
  }
  const onChange = event => {
    fields[event.target.name]
      .value = event.target.value
    setFields({ ...fields })
  }
  const onSubmit = event => {
    // use & for not ignoring next
    // operands if previous is false
    const isValid =
      validateName() &
      validateEmail()

    if (!isValid) {
      event.preventDefault()
      setFields({ ...fields })
    }
  }

  return <>
    <CloseIcon className='welcome-popup__close'/>

    <h2 className='welcome-popup__title'>
      Get a portfolio
    </h2>

    <p className='welcome-popup__text'>
      Enter your email and we will get back to you shortly!
    </p>

    <div className='welcome-popup__fields'>
      <Field name='name' placeholder='Your name'
        field={ fields.name } {...{ onChange, onBlur }} readOnly={ isLoading } autoFocus/>
      <Field name='email' placeholder='Your email address'
        field={ fields.email } {...{ onChange, onBlur }} readOnly={ isLoading }/>
      <Field name='about' placeholder='Tell us about your project'
        field={ fields.about } {...{ onChange }} readOnly={ isLoading } isTextarea/>
    </div>

    <div className='welcome-popup__footer'>
      <button className='welcome-popup__button welcome-popup__button-back' onClick={ onBack } disabled={ isLoading }>
        <Home/>
      </button>
      <button className='welcome-popup__button' onClick={ onSubmit } disabled={ isLoading }>
        { isLoading ? 'LOADING...' : 'SEND' }
      </button>
    </div>
  </>
}
